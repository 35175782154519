<template>
  <v-card outlined class="mt-0">
    <v-card-title>
      LISTADO DE USUARIOS
      <v-btn class="ml-5" color="green" @click="showCreateModal = true">
        <v-icon left>mdi-plus</v-icon>
        Agregar Usuario
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table-server :headers="headers" :items="usuarios.data" v-if="usuarios.data" :loading="isLoading"
        v-model:options="options" :items-length="totalUsuarios" density="compact" class="elevation-0"
        :items-per-page-options="itemsPorPagina">

        <!-- Mostrar el nombre del usuario -->
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <!-- Mostrar el correo del usuario -->
        <template v-slot:[`item.email`]="{ item }">
          {{ item.email }}
        </template>

        <!-- Mostrar el rol del usuario -->
        <template v-slot:[`item.rol`]="{ item }">
          {{ item.rol.descripcion }}
        </template>

        <!-- Acciones: Editar y Eliminar usuario -->
        <template v-slot:[`item.acciones`]="{ item }">
          <v-btn icon color="orange" @click="openEditModal(item)" density="compact" variant="text">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="openDeleteModal(item.id)" density="compact" variant="text">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table-server>
    </v-card-text>

    <!-- Modal de Edición de Usuario -->
    <v-dialog v-model="showEditModal" max-width="600px">
      <v-card>
        <v-card-title class="headline">Editar Usuario</v-card-title>
        <v-card-text>
          <v-text-field v-model="editedUser.name" label="Nombre" outlined class="mb-4"></v-text-field>
          <v-text-field v-model="editedUser.email" label="Correo Electrónico" outlined class="mb-4"></v-text-field>
          <v-select v-model="editedUser.rol_id" :items="roles" item-value="id" item-title="descripcion" label="Rol"
            outlined class="mb-4" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveEdit">Guardar</v-btn>
          <v-btn text @click="showEditModal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Creación de Nuevo Usuario -->
    <v-dialog v-model="showCreateModal" max-width="600px">
      <v-card>
        <v-card-title class="headline">Agregar Nuevo Usuario</v-card-title>
        <v-card-text>
          <v-text-field v-model="newUser.name" label="Nombre" outlined class="mb-4"></v-text-field>
          <v-text-field v-model="newUser.email" label="Correo Electrónico" outlined class="mb-4"></v-text-field>
          <v-text-field v-model="newUser.password" label="Contraseña" outlined type="password"></v-text-field>
          <v-select v-model="newUser.rol_id" :items="roles" item-value="id" item-title="descripcion" label="Rol"
            outlined class="mb-4" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveNewUser">Agregar</v-btn>
          <v-btn text @click="showCreateModal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteModal" max-width="500px">
      <v-card>
        <v-card-title class="headline">¿Desea eliminar este usuario?</v-card-title>
        <v-card-text>Esta acción no se puede deshacer.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="confirmDelete">Eliminar</v-btn> <!-- Aquí se usa confirmDelete -->
          <v-btn text @click="showDeleteModal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const showEditModal = ref(false);
const showCreateModal = ref(false);
const showDeleteModal = ref(false); // Para el modal de confirmación de eliminación
const editedUser = ref({ id: null, name: '', email: '', rol_id: null });
const newUser = ref({ name: '', email: '', password: '', rol_id: null });
const userIdToDelete = ref(null); // ID del usuario a eliminar
const isLoading = ref(false);
const roles = ref([]);

const usuarios = computed(() => store.state.usuarios.data);
const totalUsuarios = computed(() => usuarios.value.total);

const headers = [
  { title: 'Nombre', key: 'name', align: 'center' },
  { title: 'Correo Electrónico', key: 'email', align: 'center' },
  { title: 'Rol', key: 'rol', align: 'center' },
  { title: 'Acciones', key: 'acciones', align: 'center' },
];

const fetchRoles = async () => {
  try {
    const response = await store.dispatch('roles/fetchRoles');
    roles.value = response;
  } catch (error) {
    console.error('Error al obtener roles:', error);
  }
};

const cargarUsuarios = async () => {
  isLoading.value = true;
  try {
    await store.dispatch('usuarios/fetchUsuarios');
  } finally {
    isLoading.value = false;
  }
};

const openEditModal = (user) => {
  editedUser.value = { ...user }; // Copiar los datos del usuario seleccionado
  showEditModal.value = true;
};

const openDeleteModal = (userId) => {
  userIdToDelete.value = userId;
  showDeleteModal.value = true;
};

const saveNewUser = async () => {
  try {
    await store.dispatch('usuarios/createUser', newUser.value);
    showCreateModal.value = false;
    cargarUsuarios();
  } catch (error) {
    console.error('Error al crear usuario:', error);
  }
};

const saveEdit = async () => {
  try {
    await store.dispatch('usuarios/updateUser', editedUser.value);
    showEditModal.value = false;
    cargarUsuarios();
  } catch (error) {
    console.error('Error al editar usuario:', error);
  }
};

const confirmDelete = async () => {
  try {
    await store.dispatch('usuarios/deleteUser', userIdToDelete.value);
    showDeleteModal.value = false;
    cargarUsuarios();
  } catch (error) {
    console.error('Error al eliminar usuario:', error);
  }
};

onMounted(() => {
  cargarUsuarios();
  fetchRoles();
});
</script>
