import axios from "axios";
const roles = {
    namespaced: true,
    state: {
      roles: [],
    },
    mutations: {
      SET_ROLES(state, roles) {
        state.roles = roles;
      },
    },
    actions: {
      async fetchRoles({ commit }) {
        const response = await axios.get('/api/rol');
        commit('SET_ROLES', response.data);
        return response.data;
      },
    },
  };
  
  export default roles;
  