
import axios from "axios";

const usuarios = {
    namespaced: true,
    state: {
      data: [],
    },
    mutations: {
      SET_USUARIOS(state, usuarios) {
        state.data = usuarios;
      },
    },
    actions: {
      async fetchUsuarios({ commit }) {
        const response = await axios.get('/api/usuario');
        commit('SET_USUARIOS', response.data);
      },
      async createUser(_, usuario) {
        await axios.post('/api/usuario', usuario);
      },
      async updateUser(_, usuario) {
        await axios.put(`/api/usuario/${usuario.id}`, usuario);
      },
      async deleteUser(_, id) {
        await axios.delete(`/api/usuario/${id}`);
      },
    },
  };
  
  export default usuarios;
  